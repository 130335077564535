import React from 'react';
import { graphql } from 'gatsby';
import PlayerList from '../components/PlayerList';
import TeamFilter from '../components/TeamFilter';
import SEO from '../components/SEO';

export default function PlayersPage({ data, pageContext }) {
  const players = data.players.nodes;
  players.sort((a, b) => (a.debut < b.debut ? 1 : -1));

  return (
    <>
      <SEO
        title={
          pageContext.team
            ? `Japanese MLB players who played for ${pageContext.team}`
            : `History and information on MLB players from Japan`
        }
      />
      <TeamFilter activeTeam={pageContext.team} />
      <p>
        There are {players.length} Japanese MLB players
        {pageContext.team
          ? ` played for the ${pageContext.team}`
          : ` in the database`}
      </p>

      <PlayerList players={players} />


    </>
  );
}

export const query = graphql`
  query PlayerQuery($team: [String]) {
    players: allSanityPlayer(
      filter: { teamsplayed: { elemMatch: { name: { in: $team } } } }
    ) {
      nodes {
        name
        namejp
        id
        status
        position
        dob
        slug {
          current
        }
        bats
        dod
        href_bbref
        nameyomi
        size
        throws
        href_mlb
        href_nikkan
        href_npb
        teamsplayed {
          name
          namejp
          slug {
            current
          }
          image {
            asset {
              fixed {
                src
              }
            }
          }
          league
          mlb
        }
        currentteam {
          mlb
          name
          league
          division
          slug {
            current
          }
          image {
            asset {
              fixed {
                src
              }
            }
          }
          namejp
          color
        }
        currentminor
        debut
        icon
      }
    }
    allSanityStatus(
      filter: { teamsplayed: { elemMatch: { name: { in: $team } } } }
    ) {
      nodes {
        year
        teamsplayed {
          name
          slug {
            current
          }
        }
      }
    }
  }
`;
