import React from 'react';
import styled from 'styled-components';
import { Link, Img } from 'gatsby';

import rhpImg from '../assets/images/rhp.gif';
import lhpImg from '../assets/images/lhp.gif';
import rhbImg from '../assets/images/rhb.gif';
import lhbImg from '../assets/images/lhb.gif';

const PlayerGridStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1rem;
`;

const PlayerListStyle = styled.div`
  margin: 0;
  padding: 1.5rem;
  border: 1px solid var(--grey);
  --cast: 2px;
  box-shadow: var(--cast) var(--cast) 0 var(--grey);
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .player-icon {
    display: block;
    float: left;
    width: 55px;
  }
  .icon {
    width: 55px;
    height: 160px;
    margin-right: 1.5rem;
    image-rendering: pixelated;
  }
  .teamlogo {
    width: 13px;
    margin-right: 5px;
    image-rendering: pixelated;
  }
  .infobox {
    padding-left: 65px;
  }
  h3 {
    font-size: 1.7rem;
  }
  p {
    margin: 0.4rem 0;
    position: relative;
    strong {
      font-family: var(--hed-family);
      top: 0.1em;
      font-size: 10px;
    }
    .pos {
      font-family: var(--hed-family);
      font-size: 10px;
      top: 0.15em;
      color: var(--muted);
    }
    .team {
      font-weight: 700;
    }
    .namejp {
      font-size: 11px;
    }
    .playersize {
      display: block;
      font-size: 9px;
      letter-spacing: -1px;
      color: var(--muted);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: inherit;
    }
  }
  .team-also {
    color: var(--muted);
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem 1rem;
    font-size: 1rem;
    li {
      display: block;
      background: var(--grey);
      padding: 2px;
      &:hover {
        background: var(--red);
        a {
          color: white;
        }
      }
    }
  }
`;

function SinglePlayer({ player }) {
  // list additional teams
  let otherTeams;
  if (player.teamsplayed && player.teamsplayed.length) {
    otherTeams = (
      <p className="team-also">
        ({player.teamsplayed.map((team) => team.name).join(', ')})
      </p>
    );
  }
  // build image from icon (Deafult to right handed pitcher)
  let imagelink;
  if (player.icon === 'lhp') {
    imagelink = lhpImg;
  } else if (player.icon === 'lhb') {
    imagelink = lhbImg;
  } else if (player.icon === 'rhb') {
    imagelink = rhbImg;
  } else {
    imagelink = rhpImg;
  }
  function calculateAge(birthday, debut = Date.now()) {
    //  or if person is dead or calculating debut age, use second para
    const ageDifMs = new Date(debut) - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  const nameParts = player.name.split(' ');
  const debuteDateAndAge = player.debut ? (
    <p>
      <span className="debut">
        MLB Debut:
        <br />
        <strong>
          {player.debut} ({calculateAge(player.dob, player.debut)})
        </strong>
      </span>
    </p>
  ) : (
    <p>
      <span className="debut">MLB Debut: Not Yet </span>
    </p>
  );
  const playerSize = player.size ? (
    <p>
      <strong className="playersize">{player.size}</strong>
    </p>
  ) : null;
  const bblink = player.href_bbref ? (
    <li>
      <a href={player.href_bbref}>BB-Ref</a>
    </li>
  ) : null;
  const mlblink = player.href_mlb ? (
    <li>
      <a href={player.href_mlb}>MLB</a>
    </li>
  ) : null;
  const playerLinks = (
    <ul>
      {bblink}
      {mlblink}
      <li>
        <a href={`https://news.google.com/search?q=${player.name}`}>News</a>
      </li>
      <li>
        <a
          href={`https://news.google.com/search?q=${player.namejp}&hl=ja&gl=JP&ceid=JP:ja`}
        >
          ニュース
        </a>
      </li>
      <li>
        <a href={`https://en.wikipedia.org/wiki/${player.name}`}>Wiki</a>
      </li>
      <li>
        <a href={`https://ja.wikipedia.org/wiki/${player.namejp}`}>ウィキ</a>
      </li>
      <li>
        <a href={`https://www.baseball-reference.com/bullpen/${player.name}`}>
          Bullpen
        </a>
      </li>
    </ul>
  );

  return (
    <PlayerListStyle>
      <Link to={`/player/${player.slug.current}`} className="player-icon">
        <img
          className="icon"
          src={imagelink}
          alt=""
          style={{
            backgroundColor: player.currentteam.color,
          }}
        />
      </Link>
      <div className="infobox">
        <Link to={`/player/${player.slug.current}`}>
          <h3 className="player-name">
            {nameParts[0]}
            <br />
            {nameParts[1]}
          </h3>
        </Link>
        <p className="current-team">
          <span className="pos">{player.position} </span>
          <Link
            to={`/team/${player.currentteam.slug.current}`}
            style={{ color: player.currentteam.color }}
          >
            <img
              src={player.currentteam.image?.asset?.fixed.src}
              className="teamlogo"
              alt={player.currentteam.name}
            />
            <span className="team" style={{ color: player.currentteam.color }}>
              {player.currentteam.name}
              {player.currentminor ? ' (Minor)' : ''}
            </span>
          </Link>
        </p>
        {otherTeams || ''}
        <p>
          <span className="namejp">
            {player.namejp} ({player.nameyomi})
          </span>
        </p>
        <p>
          <span className="bats">
            Bats: <strong>{player.bats}</strong>{' '}
          </span>
          <span className="throws">
            Throws: <strong>{player.throws}</strong>
          </span>
        </p>
        <p>
          <span className="dob">
            Current Age{' '}
            <strong>
              {player.dod
                ? calculateAge(player.dob, player.dod)
                : calculateAge(player.dob)}
            </strong>
          </span>
        </p>
        {playerSize}
        {debuteDateAndAge}
        {playerLinks}
      </div>
    </PlayerListStyle>
  );
}

export default function PlayerList({ players }) {
  return (
    <div>
      <PlayerGridStyles>
        {players.map((player) => (
          <SinglePlayer player={player} key={player.id} />
        ))}
      </PlayerGridStyles>
    </div>
  );
}
