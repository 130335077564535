import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const TeamsStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
  margin-bottom: 3rem;
  a {
    text-decoration: none;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0 1rem;
    align-items: center;
    background: var(--grey);
    padding: 5px;
    border-radius: 2px;
    .count {
      background: white;
      padding: 2px 5px;
    }
    &.mlb {
      background: var(--darkgrey);
    }
    &.active {
      background: var(--red);
      color: white;
      .count {
        color: var(--red);
      }
    }
  }
`;

function countEverOnTeam(players) {
  // console.log(players);
  // return number of players
  // flatten teams played, then create one big array
  const counts = players
    .map((player) => player.teamsplayed)
    .flat()
    .reduce((acc, team) => {
      // check if this is an existing team
      const existingTeam = acc[team.id];
      if (existingTeam) {
        // if it is increment by 1
        existingTeam.count += 1;
      } else {
        // if not, create a new entry
        acc[team.id] = {
          id: team.id,
          name: team.name,
          mlb: team.mlb,
          slug: team.slug.current,
          count: 1,
        };
      }
      return acc;
    }, {});
  // sort them based on their counts, then return
  return Object.values(counts).sort((a, b) => b.count - a.count);
}
export default function TeamFilter({ activeTeam }) {
  const { players } = useStaticQuery(graphql`
    query {
      players: allSanityPlayer {
        nodes {
          teamsplayed {
            name
            id
            mlb
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const teamsWithCounts = countEverOnTeam(players.nodes);
  // console.log(teamsWithCounts);
  // sort the team name by alphabet and then move MLB teams first
  const teamSorted = teamsWithCounts
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .sort((a, b) => (a.mlb > b.mlb ? -1 : 1));
  return (
    <TeamsStyles>
      <Link to="/players">
        <span className="name">All</span>
        <span className="count">{players.nodes.length}</span>
      </Link>
      {teamSorted.map(function (team) {
        let classnames = team.name === activeTeam ? 'active' : '';
        if (team.mlb) {
          classnames += ' mlb';
        }
        return (
          <Link to={`/team/${team.slug}`} key={team.id} className={classnames}>
            {team.name} <span className="count">{team.count}</span>
          </Link>
        );
      })}
    </TeamsStyles>
  );
}
